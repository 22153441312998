import config from '../config';
import superagent from '../utils/superagent';
import RedeemResult from './RedeemResult';
import * as RedeemResults from './RedeemResults';

class CoreApiClient {

  static async redeemItem(username: string, code: string): Promise<Object> {
    return superagent
      .post(`${this._baseUrl()}/vouchers/publicRedeem`)
      .send({ username, code })
      .then(res => RedeemResults[res.body.type](res.body.count))
      .catch(err => new RedeemResult(err.response.body));
  }

  static _baseUrl(): string {
    return config.coreApi.baseUrlProd;
  }
}

export default CoreApiClient;

import { handleActions } from '../reducers';
import * as Actions from './actionCreators';
import * as storeKeys from './storeKeys';

const initialState = {
  [storeKeys.KEY_RESULT]: undefined,
};

export default handleActions({
  [Actions.SET_RESULT]: (state, action) => ({
    ...state,
    [storeKeys.KEY_RESULT]: action.payload.result,
  }),
}, initialState);

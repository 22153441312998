import { createAction } from '../actions';
import { CoreApiClient, logCatch } from '../api';

export const SET_RESULT = 'SET_RESULT';

export const redeemItem = (username: string, code: string) =>
  async (dispatch: any) => {
    try {
      const result = await CoreApiClient.redeemItem(username, code);
      dispatch(createAction(SET_RESULT, { result }));
    } catch (e) { logCatch(e); }
  };

export const clearResult = () =>
  async (dispatch: any) => {
    await dispatch(createAction(SET_RESULT, { result: undefined }));
  };

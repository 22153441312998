class RedeemResult {

  _code: number;
  _message: string;

  constructor({ code, message }) {
    this._code = code;
    this._message = message;
  }

  get code() {
    return this._code;
  }

  get message() {
    return this._message;
  }
}

export default RedeemResult;

import CoreApiClient from './CoreApiClient';
import RedeemResult from './RedeemResult';

const logCatch = (e: Object) => {
  console.error(e);
  console.warn('Wrong Server?');
};

export {
  CoreApiClient,
  logCatch,
  RedeemResult
};

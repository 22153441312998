import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RedeemComponent from './RedeemComponent';
import * as actionCreators from './actionCreators';
import * as selectors from './selectors';
import { RedeemResult } from '../api';

const mapStateToProps = state => ({
  result: selectors.result(state),
});

class RedeemContainer extends React.Component {

  state = { loading: false };

  _startLoading = () => {
    this.setState({ loading: true });
  };

  _stopLoading = () => {
    this.setState({ loading: false });
  };

  _handleClickRedeem = async (username: string, code: string) => {
    const { dispatch } = this.props;
    this._startLoading();
    await dispatch(actionCreators.redeemItem(username, code));
    this._stopLoading();
  };

  _handleClickBack = async () => {
    const { dispatch } = this.props;
    await dispatch(actionCreators.clearResult());
  };

  render() {
    const { result } = this.props;
    const { loading } = this.state;

    return (
      <RedeemComponent
        result={result}
        handleClickRedeem={this._handleClickRedeem}
        handleClickBack={this._handleClickBack}
        loading={loading}
      />
    );
  }

}

RedeemContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  result: PropTypes.instanceOf(RedeemResult)
};

export default connect(mapStateToProps)(RedeemContainer);

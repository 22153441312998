import React from 'react';
import PropTypes from 'prop-types';
import Wave from 'react-wavify';
import Fade from 'react-reveal/Fade';
import styled from '@emotion/styled';
import Spinner from 'react-spinkit';
import { RedeemResult } from '../api';

const ASSETS_IMAGE_BACKGROUND_TILE_DARK = require('../assets/images/assets_images_background_tile_dark.png');
const ASSETS_IMAGE_WOLVESVILLE_ICON = require('../assets/images/assets_images_wolvesville_icon.png');

class RedeemComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      code: "",
    };
  }

  componentDidMount() {
    if (window.location.hash) {
      this.setState({ code: window.location.hash.substr(1) });
    }
  }

  _handleClickRedeem = async () => {
    const { username, code } = this.state;
    const { handleClickRedeem } = this.props;
    await handleClickRedeem(username, code);
    this.setState({ code: "" });
  };

  _handleClickBack = async () => {
    const { handleClickBack } = this.props;
    await handleClickBack();
  };

  render() {
    const { username, code } = this.state;
    const { result, loading } = this.props;

    return (
      <div>
        <Footer>
          <Wave
            fill="#fc226e"
            options={{
              height: 20,
              amplitude: 50,
              speed: 0.1,
              points: 7
            }}
          />
          <FooterFill>
            <Imprint>
              <center>
                <a style={{ color: '#FFF' }} rel="noopener noreferrer" target="_blank" href="https://github.com/lucienbl">Made by Lucien</a><br /><br />
                <Link rel="noopener noreferrer" target="_blank" href="https://legal.wolvesville.com/imprint.html">Imprint&nbsp;&nbsp;•&nbsp;&nbsp;</Link>
                <Link rel="noopener noreferrer" target="_blank" href="https://legal.wolvesville.com/privacy-policy.html">Privacy Policy&nbsp;&nbsp;•&nbsp;&nbsp;</Link>
                <Link rel="noopener noreferrer" target="_blank" href="https://legal.wolvesville.com/tos.html">Terms of Service</Link>
              </center>
            </Imprint>
          </FooterFill>
        </Footer>

        <Fade>
          <center>
            <CardContainer>
              <CardHeader>
                <Logo src={ASSETS_IMAGE_WOLVESVILLE_ICON} />
                <Title>Wolvesville</Title>
              </CardHeader>

              {result && result.code === 200 ?
                <div>
                  <Subtitle style={{ width: '70%', height: '40%' }}>{result.message}</Subtitle>
                  <Button onClick={this._handleClickBack}>Back</Button>
                </div>
              :
                <div>
                  <Subtitle>Did you receive a code for Wolvesville? Then please enter your username and code below to redeem your gift. Make sure to spell your username exactly like it appears in the game!</Subtitle>
                  <Input type="text" placeholder="Username" value={username} onChange={e => this.setState({ username: e.target.value })} />
                  <Input type="text" placeholder="Code" value={code} onChange={e => this.setState({ code: e.target.value })} />
                  <br />
                  {result && <span style={{ color: 'red' }}><br/>{result.message}<br/></span>}
                  <Button onClick={loading ? () => {} : this._handleClickRedeem}>{loading ? <div><Spinner noFadeIn name='three-bounce' color="#FFF" /></div> : 'Redeem'}</Button>
                </div>
              }
            </CardContainer>
          </center>
        </Fade>

        <Background />
      </div>
    );
  }
}

const Imprint = styled.div`
  position: absolute;
  bottom: 15px;
  right: 1%;
  left: 1%;
`;

const Link = styled.a`
  text-decoration: none;
  text-align: center;
  color: #EEE;
  font-size: 13px;
`;

const Title = styled.h1`
  color: white;
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 0px;
  
  @media only screen and (max-width: 700px) {
    font-size: 35px;
  }
`;

const Subtitle = styled.h2`
  color: #999;
  font-size: 18px;
  margin: 0px 5% 20px 5%;
  
  @media only screen and (max-width: 700px) {
    font-size: 15px;
  }
`;

const Logo = styled.img`  
  width: 140px;
  height: 140px;
  
  @media only screen and (max-width: 700px) {
     width: 100px;
     height: 100px;
  }
`;

const CardHeader = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
`;

const CardContainer = styled.div`
  position: absolute;
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: #41454B;
  margin-right: 25%;
  margin-left: 25%;
  border-radius: 10px;
  padding: 10px;
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  z-index: 50;
  
  @media only screen and (min-width: 1450px) {
     margin-right: 30%;
     margin-left: 30%;
  }
  
  @media only screen and (max-width: 1200px) {
     margin-right: 15%;
     margin-left: 15%;
  }
  
  @media only screen and (max-width: 900px) {
     margin-right: 5%;
     margin-left: 5%;
  }
`;

const Button = styled.div`
  border: 0px;
  background-color: #fc226e;
  width: 20%;
  margin-top: 30px;
  padding: 15px;
  font-size: 17px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  
  @media only screen and (max-width: 500px) {
     width: 70%;
  }
`;

const Input = styled.input`
  width: 70%;
  background-color: #FFFFFF00;
  padding-left: 20px;
  font-size: 18px;
  color: #AAA;
  margin-top: 20px;
  border: solid 2px #888;
  border-radius: 5px;
  height: 40px;
  
  &:focus {
    border-color: #fc226e;
    outline: none;
  }
  
  ::placeholder {
    font-size: 18px;
    color: #888;
  }
`;

const FooterFill = styled.div`
  height: 100%;
  width: 100%; 
  background-color: #fc226e; 
  margin-top: -10px;
  position: relative;
`;

const Footer = styled.div`
  width: 100%;
  height: 40%; 
  position: absolute; 
  bottom: 0px;
`;

const Background = styled.div`
  width: 100%;
  height: 100%; 
  position: absolute; 
  top: 0px;
  bottom: 0px;
  right: 0px; 
  left: 0px;
  z-index: -1;
  background-image: url("${ASSETS_IMAGE_BACKGROUND_TILE_DARK}");
  background-repeat: repeat;
  background-size: 25% auto;
  
  @media only screen and (max-width: 500px) {
     background-size: 100% auto;
  }
`;

RedeemComponent.propTypes = {
  result: PropTypes.instanceOf(RedeemResult),
  handleClickRedeem: PropTypes.func.isRequired,
  handleClickBack: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default RedeemComponent;
